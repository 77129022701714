@import "themes";

@import "@nebular/theme/styles/globals";
@import "bootstrap/scss/bootstrap";

/* Style for the quill html editor/viewer */
@import "quill/dist/quill.core.css";
@import "quill/dist/quill.snow.css";

@include nb-install() {
  @include nb-theme-global();
}
/* You can add global styles to this file, and also import other style files */

.body {
  background-color: nb-theme(color-basic-100);
}

.appearance-ghost {
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
  }
}

.content {
  margin-top: 1rem;
}

.max-width {
  max-width: 1600px;
  margin: auto;
  padding-top: 1rem;
}

.max-auth-width {
  max-width: 35rem;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.margin-top {
  margin-top: 1rem;
}

.margin-top-2 {
  margin-top: 2rem;
}

.margin-bottom {
  margin-bottom: 1rem;
}

.margin-bottom-2 {
  margin-bottom: 2rem;
}

.negative-margin-bottom {
  margin-bottom: -1rem;
}

.margin-left {
  margin-left: 1rem;
}

.margin-right {
  margin-right: 1rem;
}

.no-bottom-margin {
  margin-bottom: 0;
}

.no-col-padding {
  padding-left: 0;
  padding-right: 0;
}

.padding-left {
  padding-left: 1rem;
}

.required-asterisk {
  color: nb-theme(color-danger-500) !important;
  font-weight: bold;
  margin-left: 3px;
  font-size: 1.2em;
}

.full-width {
  width: 100%;
}

.image-primary-color {
  fill: nb-theme(color-image);
}

.image-primary-stroke {
  stroke: nb-theme(color-image);
}

.landing-page-image-color {
  fill: nb-theme(color-landing-page-image)
}

.date-icon {
  font-size: smaller;
}

.task-deadline {
  color: nb-theme(color-danger-400);
}

.popover-padding {
  padding: 0.5rem;
}

.tooltip-icon {
  font-size: small;
  margin-left: .25rem;
}

.drag-drop-icon {
  fill: nb-theme(color-basic-500);

  &.dark {
    fill: nb-theme(color-basic-600);
  }
}

.draggable {
  cursor: move;
  height: 1rem;
}

.custom-basic-outline-button {
  color: nb-theme(text-basic-color) !important;
  background-color: nb-theme(color-basic-300) !important;
}

.custom-basic-outline-button:hover {
  background-color: nb-theme(color-basic-400) !important;
}

.spacer {
  height: 1rem;
}

.merge-control {
  width: 100%;

  p {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.gap {
  gap: 1rem;
}

nb-calendar-actions {
  button {
    text-transform: capitalize !important;
  }
}

/**
  for sharedb presence caret
 */
.blinking-icon {
  background: center no-repeat;
  width: 16px;
  height: 16px;
  margin-left: -0.4rem;
  margin-top: -0.05rem;
  border: none;
  display: inline-block;
  animation: blink 1.5s ease infinite;
  pointer-events: none;
}

@keyframes blink {
  from, to { opacity: 1 }
  50% { opacity: 0 }
}
